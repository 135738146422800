//ESLINT GLOBAL IGNORES
/* global $ */
/* global EmojiButton */
/* global grecaptcha */
import * as ThumbmarkJS from "@thumbmarkjs/thumbmarkjs"; '@thumbmarkjs/thumbmarkjs'

document.addEventListener("DOMContentLoaded", function () {
  var minimumDisplayTime = 1000;
  var preloader = document.getElementById("preloader");
  var tick = document.getElementById("tick");

  setTimeout(function () {
    tick.style.display = "block";
  }, 500);

  setTimeout(function () {
    preloader.classList.add("slide-down");

    setTimeout(function () {
      preloader.style.display = "none";
    }, 6000);
  }, minimumDisplayTime);
});
var container = document.querySelector(".snowflake-container");
var imagePaths = [
  "./assets/static/img/favicon.png",
  "./assets/static/img/faviconGreen.png",
  "./assets/static/img/faviconBlue.png",
];
var imageIndex = 0;

for (var i = 0; i < 10; i++) {
  var snowflake = document.createElement("div");
  snowflake.className = "snowflake";

  var img = document.createElement("img");
  img.src = imagePaths[imageIndex];
  img.style.transform = `rotate(${Math.random() * 360}deg)`;
  snowflake.appendChild(img);

  imageIndex = (imageIndex + 1) % imagePaths.length;

  var posX = Math.random() * 100;
  var duration = Math.random() * 5 + 20;
  var delay = Math.random() * 50;

  snowflake.style.left = posX + "%";
  snowflake.style.animation = `fall ${duration}s linear infinite`;
  snowflake.style.animationDelay = delay + "s";

  container.appendChild(snowflake);
}

const ffa = process.env.VITE_FFA_URL;
const teams = process.env.VITE_TEAMS_URL;
const duels = process.env.VITE_DUELS_URL;
const experimental = process.env.VITE_EXPERIMENTAL_URL;


let startServer = ffa;
if(Number(process.env.development)){
  startServer = teams;
}
let currentServerString = "FFA";
let currentServer = startServer;
const recaptchaV3SiteKey = "6LeF9bwnAAAAABw0D8sgEIOqY4AI09cpBwK4jZfL";
let players = false;
let spectateDefault = false;
let verificationFailed = false;
var zIndexChanged = false;
let maxAttempts = 0;

(function () {
  "use strict";

  if (
    typeof WebSocket === "undefined" ||
    typeof DataView === "undefined" ||
    typeof ArrayBuffer === "undefined" ||
    typeof Uint8Array === "undefined"
  ) {
    alert(
      "Your browser does not support required features, please update your browser or get a new one."
    );
    window.stop();
  }

  function byId(id) {
    return document.getElementById(id);
  }
  /*
    function byClass(clss, parent) {
        return (parent || document).getElementsByClassName(clss);
    }
    */

  class Sound {
    constructor(src, volume, maximum) {
      this.src = src;
      this.volume = typeof volume === "number" ? volume : 0.5;
      this.maximum = typeof maximum === "number" ? maximum : Infinity;
      this.elms = [];
    }
    play(vol) {
      if (typeof vol === "number") this.volume = vol;
      const toPlay = this.elms.find((elm) => elm.paused) ?? this.add();
      toPlay.volume = this.volume;
      toPlay.play();
    }
    add() {
      if (this.elms.length >= this.maximum) return this.elms[0];
      const elm = new Audio(this.src);
      this.elms.push(elm);
      return elm;
    }
  }

  const LOAD_START = Date.now();

  Array.prototype.remove = function (a) {
    const i = this.indexOf(a);
    return i !== -1 && this.splice(i, 1);
  };

  Element.prototype.hide = function () {
    this.style.display = "none";
    if (this.style.opacity === 1) this.style.opacity = 0;
  };

  Element.prototype.show = function (seconds) {
    this.style.display = "";
    if (!seconds) return;
    this.style.transition = `opacity ${seconds}s ease 0s`;
    this.style.opacity = 1;
  };

  class Color {
    static fromHex(color) {
      let hex = color;
      if (color.startsWith("#")) hex = color.slice(1);
      if (hex.length === 3)
        hex = hex
          .split("")
          .map((c) => c + c)
          .join("");
      if (hex.length !== 6) throw new Error(`Invalid color ${color}`);
      const v = parseInt(hex, 16);
      return new Color((v >>> 16) & 255, (v >>> 8) & 255, v & 255, `#${hex}`);
    }
    constructor(r, g, b, hex) {
      this.r = r;
      this.g = g;
      this.b = b;
      this.hexCache = hex;
    }
    clone() {
      return new Color(this.r, this.g, this.b);
    }
    toHex() {
      if (this.hexCache) return this.hexCache;
      return (this.hexCache = `#${(
        (1 << 24) |
        (this.r << 16) |
        (this.g << 8) |
        this.b
      )
        .toString(16)
        .slice(1)}`);
    }
    darken(grade = 1) {
      grade /= 10;
      this.r *= 1 - grade;
      this.g *= 1 - grade;
      this.b *= 1 - grade;
      return this;
    }
    darker(grade = 1) {
      return this.clone().darken(grade);
    }
  }

  function cleanupObject(object) {
    for (const i in object) delete object[i];
  }

  class Writer {
    constructor(littleEndian) {
      this.writer = true;
      this.tmpBuf = new DataView(new ArrayBuffer(8));
      this._e = littleEndian;
      this.reset();
      return this;
    }
    reset(littleEndian = this._e) {
      this._e = littleEndian;
      this._b = [];
      this._o = 0;
    }
    setUint8(a) {
      if (a >= 0 && a < 256) this._b.push(a);
      return this;
    }
    setInt8(a) {
      if (a >= -128 && a < 128) this._b.push(a);
      return this;
    }
    setUint16(a) {
      this.tmpBuf.setUint16(0, a, this._e);
      this._move(2);
      return this;
    }
    setInt16(a) {
      this.tmpBuf.setInt16(0, a, this._e);
      this._move(2);
      return this;
    }
    setUint32(a) {
      this.tmpBuf.setUint32(0, a, this._e);
      this._move(4);
      return this;
    }
    setInt32(a) {
      this.tmpBuf.setInt32(0, a, this._e);
      this._move(4);
      return this;
    }
    setFloat32(a) {
      this.tmpBuf.setFloat32(0, a, this._e);
      this._move(4);
      return this;
    }
    setFloat64(a) {
      this.tmpBuf.setFloat64(0, a, this._e);
      this._move(8);
      return this;
    }
    _move(b) {
      for (let i = 0; i < b; i++) this._b.push(this.tmpBuf.getUint8(i));
    }
    setStringUTF8(s) {
      const bytesStr = unescape(encodeURIComponent(s));
      for (let i = 0, l = bytesStr.length; i < l; i++)
        this._b.push(bytesStr.charCodeAt(i));
      this._b.push(0);
      return this;
    }
    build() {
      return new Uint8Array(this._b);
    }
  }

  class Reader {
    constructor(view, offset, littleEndian) {
      this.reader = true;
      this._e = littleEndian;
      if (view) this.repurpose(view, offset);
    }
    repurpose(view, offset) {
      this.view = view;
      this._o = offset || 0;
    }
    getUint8() {
      return this.view.getUint8(this._o++, this._e);
    }
    getInt8() {
      return this.view.getInt8(this._o++, this._e);
    }
    getUint16() {
      return this.view.getUint16((this._o += 2) - 2, this._e);
    }
    getInt16() {
      return this.view.getInt16((this._o += 2) - 2, this._e);
    }
    getUint32() {
      return this.view.getUint32((this._o += 4) - 4, this._e);
    }
    getInt32() {
      return this.view.getInt32((this._o += 4) - 4, this._e);
    }
    getFloat32() {
      return this.view.getFloat32((this._o += 4) - 4, this._e);
    }
    getFloat64() {
      return this.view.getFloat64((this._o += 8) - 8, this._e);
    }
    getStringUTF8() {
      let s = "",
        b;
      while ((b = this.view.getUint8(this._o++)) !== 0)
        s += String.fromCharCode(b);
      return decodeURIComponent(escape(s));
    }
  }

  class Logger {
    static get verbosity() {
      return  process.env.DEBUG === 'true' ? 7 : 2;
    }
    static error() {
      if (Logger.verbosity > 0) console.error.apply(null, arguments);
    }
    static warn() {
      if (Logger.verbosity > 1) console.warn.apply(null, arguments);
    }
    static info() {
      if (Logger.verbosity > 2) console.info.apply(null, arguments);
    }
    static debug() {
      if (Logger.verbosity > 3) console.debug.apply(null, arguments);
    }
  }

  const WEBSOCKET_URL = null;
  const SKIN_URL = "./skins/";
  const USE_HTTPS = "https:" === window.location.protocol;
  const EMPTY_NAME = "An unnamed cell";
  const QUADTREE_MAX_POINTS = 1;
  const CELL_POINTS_MIN = 10;
  const CELL_POINTS_MAX = 50;
  const VIRUS_POINTS = 60;
  const PI_2 = Math.PI * 2;
  const SEND_254 = new Uint8Array([254, 6, 0, 0, 0]);
  const SEND_255 = new Uint8Array([255, 1, 0, 0, 0]);
  const UINT8_CACHE = {
    1: new Uint8Array([1]),
    17: new Uint8Array([17]),
    18: new Uint8Array([18]),
    19: new Uint8Array([19]),
    21: new Uint8Array([21]),
    22: new Uint8Array([22]),
    23: new Uint8Array([23]),
    24: new Uint8Array([24]),
    25: new Uint8Array([25]),
    26: new Uint8Array([26]),
    27: new Uint8Array([27]),
    28: new Uint8Array([28]),
    29: new Uint8Array([29]),
    30: new Uint8Array([30]),
    31: new Uint8Array([31]),
    33: new Uint8Array([33]),
    34: new Uint8Array([34]),
    35: new Uint8Array([35]),
    36: new Uint8Array([36]),
    37: new Uint8Array([37]),
    38: new Uint8Array([38]),
    39: new Uint8Array([39]),
    40: new Uint8Array([40]),
    41: new Uint8Array([41]),
    42: new Uint8Array([42]),
    43: new Uint8Array([43]),
    46: new Uint8Array([46]),
    254: new Uint8Array([254]),
  };
  const IE_KEYS = {
    spacebar: " ",
    esc: "esc",
  };
  const CODE_TO_KEY = {
    // Modifier keys
    ShiftLeft: "shift",
    ShiftRight: "shift",
    ControlLeft: "ctrl",
    ControlRight: "ctrl",
    AltLeft: "alt",
    AltRight: "alt",

    // Letter keys
    KeyA: "a",
    KeyB: "b",
    KeyC: "c",
    KeyD: "d",
    KeyE: "e",
    KeyF: "f",
    KeyG: "g",
    KeyH: "h",
    KeyI: "i",
    KeyJ: "j",
    KeyK: "k",
    KeyL: "l",
    KeyM: "m",
    KeyN: "n",
    KeyO: "o",
    KeyP: "p",
    KeyQ: "q",
    KeyR: "r",
    KeyS: "s",
    KeyT: "t",
    KeyU: "u",
    KeyV: "v",
    KeyW: "w",
    KeyX: "x",
    KeyY: "y",
    KeyZ: "z",

    // Number keys
    Digit0: "0",
    Digit1: "1",
    Digit2: "2",
    Digit3: "3",
    Digit4: "4",
    Digit5: "5",
    Digit6: "6",
    Digit7: "7",
    Digit8: "8",
    Digit9: "9",

    // Navigation keys
    ArrowUp: "arrowUp",
    ArrowDown: "arrowDown",
    ArrowLeft: "arrowLeft",
    ArrowRight: "arrowRight",

    // Special keys
    Space: " ",
    Enter: "enter",
    Escape: "esc",
    Backspace: "backspace",
    Tab: "tab",
    Delete: "delete",
    Insert: "insert",
    Home: "home",
    End: "end",
    PageUp: "pageUp",
    PageDown: "pageDown",

    // Numpad keys
    Numpad0: "numpad0",
    Numpad1: "numpad1",
    Numpad2: "numpad2",
    Numpad3: "numpad3",
    Numpad4: "numpad4",
    Numpad5: "numpad5",
    Numpad6: "numpad6",
    Numpad7: "numpad7",
    Numpad8: "numpad8",
    Numpad9: "numpad9",
    NumpadMultiply: "numpad*",
    NumpadAdd: "numpad+",
    NumpadSubtract: "numpad-",
    NumpadDecimal: "numpad.",
    NumpadDivide: "numpad/",

    CapsLock: "capsLock",
  };
  let keybindings = {
    menu: "esc",
    feed: "w",
    split: " ",
    splitMinion: "e",
    feedMinion: "r",
    controlMinion: "q",
    freezeMinion: "t",
    collectMinion: "p",
    freeze: "o",
    merge: "m",
    supersplit: "i",
    suicide: "k",
    grow: "y",
    shrink: "u",
    clearNodes: "l",
    explode: "h",
    changeColor: "z",
    rainbow: "x",
    virus: "s",
    foodSize: "c",
    teleport: "j",
    spawnFood: "g",
    foodColor: "b",
    massFiring: "v",
    chat: "enter",
  };
  let keyUpActions = {
    [keybindings.feed]: () => {
      clearInterval(macroIntervalID);
      macroIntervalID = null;
    },
    [keybindings.controlMinion]: () => wsSend(UINT8_CACHE[19]),
  };
  let keyDownActions = {
    [keybindings.chat]: () => {
      if (escOverlayShown || !settings.showChat) return;
      if (isTyping) {
        chatBox.blur();
        if (chatBox.value.length > 0) sendChat(chatBox.value);
        chatBox.value = "";
      } else {
        chatBox.focus();
      }
    },
    [keybindings.menu]: () => {
      escOverlayShown ? hideESCOverlay() : showESCOverlay();
    },
    [keybindings.splitMinion]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[22]);
    },
    [keybindings.feedMinion]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[23]);
    },
    [keybindings.freezeMinion]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[24]);
    },
    [keybindings.collectMinion]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[25]);
    },
    [keybindings.freeze]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[26]);
    },
    [keybindings.merge]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[27]);
    },
    [keybindings.supersplit]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[28]);
    },
    [keybindings.suicide]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[29]);
    },
    [keybindings.grow]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[30]);
    },
    [keybindings.shrink]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[31]);
    },
    [keybindings.clearNodes]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[33]);
    },
    [keybindings.explode]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[34]);
    },
    [keybindings.changeColor]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[35]);
    },
    [keybindings.rainbow]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[36]);
    },
    [keybindings.virus]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[37]);
    },
    [keybindings.foodSize]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[38]);
    },
    [keybindings.teleport]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[39]);
    },
    [keybindings.spawnFood]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[40]);
    },
    [keybindings.foodColor]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[41]);
    },
    [keybindings.massFiring]: () => {
      if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[42]);
    },
    [keybindings.feed]: () => {
      if (!isTyping && !escOverlayShown) {
        if (macroIntervalID) return;
        let code = UINT8_CACHE[minionControlled ? 23 : 21];
        macroIntervalID = setInterval(() => wsSend(code), macroCooldown);
        wsSend(code);
      }
    },
    [keybindings.split]: () => {
      if (!isTyping && !escOverlayShown && (spectateDefault || freeRoam)) {
        playerRoam = true;
        spectateDefault = false;
        freeRoam = false;
        wsSend(UINT8_CACHE[minionControlled ? 22 : 17]);
      } else if (!isTyping && !escOverlayShown) {
        wsSend(UINT8_CACHE[minionControlled ? 22 : 17]);
      }
    },
    [keybindings.controlMinion]: () => {
      if (!isTyping && !escOverlayShown) {
        wsSend(UINT8_CACHE[18]);
        if (/*players &&*/ spectateDefault) {
          freeRoam = true;
          spectateDefault = false;
        } else if (/*players && */freeRoam) {
          freeRoam = false;
          spectateDefault = true;
        } else if (/*players && */playerRoam) {
          freeRoam = true;
          playerRoam = false;
        }
      }
    },
  };
  document.getElementById("bindFeed").addEventListener("click", () => {
    listenForKey("feed");
  });
  document.getElementById("bindSplit").addEventListener("click", () => {
    listenForKey("split");
  });
  document.getElementById("bindChangeColor").addEventListener("click", () => {
    listenForKey("changeColor");
  });
  function updateKeyActions() {
    keyDownActions = {
      [keybindings.chat]: () => {
        if (escOverlayShown || !settings.showChat) return;
        if (isTyping) {
          chatBox.blur();
          if (chatBox.value.length > 0) sendChat(chatBox.value);
          chatBox.value = "";
        } else {
          chatBox.focus();
        }
      },
      [keybindings.menu]: () => {
        escOverlayShown ? hideESCOverlay() : showESCOverlay();
      },
      [keybindings.splitMinion]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[22]);
      },
      [keybindings.feedMinion]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[23]);
      },
      [keybindings.freezeMinion]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[24]);
      },
      [keybindings.collectMinion]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[25]);
      },
      [keybindings.freeze]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[26]);
      },
      [keybindings.merge]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[27]);
      },
      [keybindings.supersplit]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[28]);
      },
      [keybindings.suicide]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[29]);
      },
      [keybindings.grow]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[30]);
      },
      [keybindings.shrink]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[31]);
      },
      [keybindings.clearNodes]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[33]);
      },
      [keybindings.explode]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[34]);
      },
      [keybindings.changeColor]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[35]);
      },
      [keybindings.rainbow]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[36]);
      },
      [keybindings.virus]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[37]);
      },
      [keybindings.foodSize]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[38]);
      },
      [keybindings.teleport]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[39]);
      },
      [keybindings.spawnFood]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[40]);
      },
      [keybindings.foodColor]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[41]);
      },
      [keybindings.massFiring]: () => {
        if (!isTyping && !escOverlayShown) wsSend(UINT8_CACHE[42]);
      },
      [keybindings.feed]: () => {
        if (!isTyping && !escOverlayShown) {
          if (macroIntervalID) return;
          let code = UINT8_CACHE[minionControlled ? 23 : 21];
          macroIntervalID = setInterval(() => wsSend(code), macroCooldown);
          wsSend(code);
        }
      },
      [keybindings.split]: () => {
        if (!isTyping && !escOverlayShown && (spectateDefault || freeRoam)) {
          playerRoam = true;
          spectateDefault = false;
          freeRoam = false;
          wsSend(UINT8_CACHE[minionControlled ? 22 : 17]);
        } else if (!isTyping && !escOverlayShown) {
          wsSend(UINT8_CACHE[minionControlled ? 22 : 17]);
        }
      },
      [keybindings.controlMinion]: () => {
        if (!isTyping && !escOverlayShown) {
          wsSend(UINT8_CACHE[18]);
          if (/*players &&*/ spectateDefault) {
            freeRoam = true;
            spectateDefault = false;
          } else if (/*players && */freeRoam) {
            freeRoam = false;
            spectateDefault = true;
          } else if (/*players && */playerRoam) {
            freeRoam = true;
            playerRoam = false;
          }
        }
      },
    };
    keyUpActions = {
      [keybindings.feed]: () => {
        clearInterval(macroIntervalID);
        macroIntervalID = null;
      },
      [keybindings.controlMinion]: () => wsSend(UINT8_CACHE[19]),
    };
  }

  function listenForKey(action) {
    const listener = (event) => {
      const processedKey = processKey(event);
      let displayKey = processedKey;
      if (processedKey === " ") {
        displayKey = "Space";
      }
      keybindings[action] = processedKey;
      document.getElementById(
        "bind" + action.charAt(0).toUpperCase() + action.slice(1)
      ).textContent = displayKey;
      document.removeEventListener("keydown", listener);
      saveKeybindings();
      updateKeyActions();
    };
    document.addEventListener("keydown", listener);
  }
  function saveKeybindings() {
    localStorage.setItem("keybindings", JSON.stringify(keybindings));
  }
  function loadKeybindings() {
    const storedKeybindings = localStorage.getItem("keybindings");
    if (storedKeybindings) {
      keybindings = JSON.parse(storedKeybindings);

      for (const action in keybindings) {
        const actionElementId =
          "bind" + action.charAt(0).toUpperCase() + action.slice(1);
        const actionElement = document.getElementById(actionElementId);
        if (actionElement) {
          let displayKey =
            keybindings[action] === " " ? "Space" : keybindings[action];
          actionElement.textContent = displayKey;
        }
      }

      updateKeyActions();
    }
  }

  function wsCleanup() {
    if (!ws) return;
    Logger.debug("WebSocket cleanup");
    ws.onopen = null;
    ws.onmessage = null;
    ws.close();
    ws = null;
    gameReset();
  }

  async function wsInit(url,attempts = 0) {
    if (verificationFailed) return;
    let fingerprint = await ThumbmarkJS.getFingerprint();

    updateConnectingContent("Connecting...", "Having trouble? Click on the discord link to get in touch with the support team.", false, true, true);
  
    if (ws) {
      Logger.debug("WebSocket init on existing connection");
      wsCleanup();
    }
  
    byId("connecting").show(0.5);
    wsUrl = url;
  
    try {
      const recaptchaV3Token = await grecaptcha.execute(recaptchaV3SiteKey);
      if (recaptchaV3Token) {
        ws = new WebSocket(`ws${USE_HTTPS ? "s" : ""}://${url}?recaptchaV3Token=${recaptchaV3Token}&cd=${fingerprint}`);
        ws.binaryType = "arraybuffer";
        ws.onopen = wsOpen;
        ws.onmessage = wsMessage;
        ws.onerror = wsError;
        ws.onclose = wsClose;
      }
      else {
        throw new Error("Failed to get reCAPTCHA token.");
      }
    } catch (error) {
      if (attempts++ >= 4) {
        updateConnectingContent("Connection Failed",`${currentServerString} is not available at this time. Click on the retry button or contact our support team in discord.`,
            true,
            true 
        );
      } else {
        // Otherwise, wsInit will be called 4x almost instantly despite being awaited in its function body.
        setTimeout(() => wsInit(url,attempts),attempts * 250);
      }
    }
  }
  function retryConnection() {
    verificationFailed = false;
    maxAttempts = 0;
    wsInit(currentServer);
  }

  function wsOpen() {
    reconnectDelay = 100;
    if (!verificationFailed) {
        byId("connecting").hide();
    }
  }

  function wsError(error) {
    Logger.warn(error);
  }

  function wsClose(e) {
    if (e.currentTarget !== ws) return;

    Logger.debug(`WebSocket disconnected ${e.code} (${e.reason})`);
    wsCleanup();
    maxAttempts++;

    if (maxAttempts >= 4) {
        updateConnectingContent("Connection Failed",`${currentServerString} is not available at this time. Click on the retry button or contact our support team in discord.`,
            true, // show retry button
            true  // show close button
        );
    } else {
        setTimeout(() => window.setserver(wsUrl), (reconnectDelay));
    }
}

  function wsSend(data) {
    if (!ws) return;
    if (ws.readyState !== 1) return;
    if (data.build) ws.send(data.build());
    else ws.send(data);
  }

  function resetButtonColors() {
    document.getElementById("teams-btn").style.backgroundColor = "";
    document.getElementById("duels-btn").style.backgroundColor = "";
    document.getElementById("ffa-btn").style.backgroundColor = "";
    document.getElementById("experimental-btn").style.backgroundColor ="";
  }

  function updateConnectingContent(title, message, showRetryButton, showCloseButton, showLoader = false) {
    const connectingElement = document.getElementById("connecting-content");
    let buttonsHTML = "";

    if (showRetryButton) {
        buttonsHTML += '<button id="retry-button">Retry</button>';
    }
    if (showCloseButton) {
        buttonsHTML += '<button id="close-button">Close</button>';
    }

    const loaderHTML = showLoader ? '<div class="loader"></div>' : '';

    connectingElement.innerHTML = `
        <h2>${title}</h2>
        ${loaderHTML}
        <p>${message}</p>
        ${buttonsHTML}
    `;

    if (showRetryButton) {
        document.getElementById("retry-button").addEventListener("click", retryConnection);
    }
    if (showCloseButton) {
        document.getElementById("close-button").addEventListener("click", function () {
            byId("connecting").hide();
            resetButtonColors();
        });
    }
}

function wsMessage(event) {
    if (typeof event.data === "string") {
        try {
            const messageObject = JSON.parse(event.data);
            switch (messageObject.type) {
              case "playerStats":
                    stats.playerCells = messageObject.playerCells;
                    break;
                case "recaptchaFail":
                case "proxyFail":
                case "bannedIp":
                    byId("connecting").show();
                    verificationFailed = true;
                    updateConnectingContent("Verification Failed", messageObject.reason, "Close", "close-button");
                    break;
                case "limitReached":
                case "invalidClient":
                    byId("connecting").show();
                    verificationFailed = true;
                    updateConnectingContent("Connection Failed", messageObject.reason, "Close", "close-button");
                    break;
                case "internalServerError":
                    console.log(messageObject.reason);
                    verificationFailed = false;
                    byId("connecting").hide();
                    break;
                case "recaptchaSuccess":
                    console.log("reCAPTCHA: Verification Successful");
                    verificationFailed = false;
                    byId("connecting").hide();
                    break;
            }
        } catch (e) {
            console.error("Error parsing message:", e);
        }
    } else {
    syncUpdStamp = Date.now();
    const reader = new Reader(new DataView(event.data), 0, true);
    const packetId = reader.getUint8();
    switch (packetId) {
      case 0x10: {
        // update nodes
        // consume records
        const addedCount = reader.getUint16();
        for (let i = 0; i < addedCount; i++) {
          const killer = reader.getUint32();
          const killed = reader.getUint32();
          if (!cells.byId.has(killer) || !cells.byId.has(killed)) continue;
          if (settings.playSounds && cells.mine.includes(killer)) {
            (cells.byId.get(killed).s < 20 ? pelletSound : eatSound).play(
              parseFloat(soundsVolume.value)
            );
          }
          cells.byId.get(killed).destroy(killer);
        }

        // update records
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const id = reader.getUint32();
          if (id === 0) break;

          const x = reader.getInt32();
          const y = reader.getInt32();
          const s = reader.getUint16();

          const flagMask = reader.getUint8();
          const flags = {
            updColor: !!(flagMask & 0x02),
            updSkin: !!(flagMask & 0x04),
            updName: !!(flagMask & 0x08),
            isVirus: !!(flagMask & 0x01) || !!(flagMask & 0x10),
            isEjectedMass: !!(flagMask & 0x20),
            isPellet: !!(flagMask & 0x80)
          };

          const color = flags.updColor
            ? new Color(reader.getUint8(), reader.getUint8(), reader.getUint8())
            : null;
          const skin = flags.updSkin ? reader.getStringUTF8() : null;
          const name = flags.updName ? reader.getStringUTF8() : null;

          if (cells.byId.has(id)) {
            const cell = cells.byId.get(id);
            cell.update(syncUpdStamp);
            cell.updated = syncUpdStamp;
            cell.ox = cell.x;
            cell.oy = cell.y;
            cell.os = cell.s;
            cell.nx = x;
            cell.ny = y;
            cell.ns = s;
            if (color) cell.setColor(color);
            if (name) cell.setName(name);
            if (skin) cell.setSkin(skin);
          } else {
            const cell = new Cell(id, x, y, s, name, color, skin, flags);
            cells.byId.set(id, cell);
            cells.list.push(cell);
          }
        }
        // dissapear records
        const removedCount = reader.getUint16();
        for (let i = 0; i < removedCount; i++) {
          const killed = reader.getUint32();
          if (cells.byId.has(killed) && !cells.byId.get(killed).destroyed) {
            cells.byId.get(killed).destroy(null);
          }
        }
        break;
      }
      case 0x11: {
        // update pos
        camera.target.x = reader.getFloat32();
        camera.target.y = reader.getFloat32();
        camera.target.scale = reader.getFloat32();
        camera.target.scale *= camera.viewportScale;
        camera.target.scale *= camera.userZoom;
        break;
      }
      case 0x12: {
        // clear all
        for (const cell of cells.byId.values()) {
          cell.destroy(null);
        }
        cells.mine = [];
        break;
      }
      case 0x14: {
        // clear my cells
        cells.mine = [];
        break;
      }
      case 0x15: {
        // draw line
        Logger.warn("got packet 0x15 (draw line) which is unsupported");
        break;
      }
      case 0x20: {
        // new cell
        cells.mine.push(reader.getUint32());
        break;
      }

      case 0x21:
        //clear the nodes;
        minimapnodes = [];
        var  clientCount = reader.getUint8();

        for(let x = 0; x < clientCount; x++){
          const color = new Color(reader.getUint8(), reader.getUint8(), reader.getUint8());

          let positionX =  reader.getInt32();
          let positionY=  reader.getInt32();
          let size = reader.getUint32();
          let name = reader.getStringUTF8();
          minimapnodes.push({
            color: color,
            positionX: positionX,
            positionY: positionY,
            size: size,
            name:name,
          });

          // console.log("positionx" + positionX);
          // console.log("positiony" + positionY);
        }
        break;
      case 0x30: {
        // text list
        leaderboard.items = [];
        leaderboard.type = "text";

        const lbCount = reader.getUint32();
        for (let i = 0; i < lbCount; ++i) {
          leaderboard.items.push(reader.getStringUTF8());
        }
        drawLeaderboard();
        break;
      }
      case 0x31: {
        // ffa list
        leaderboard.items = [];
        leaderboard.type = "ffa";

        const count = reader.getUint32();
        for (let i = 0; i < count; ++i) {
          const isMe = !!reader.getUint32();
          const lbName = reader.getStringUTF8();
          leaderboard.items.push({
            me: isMe,
            name: Cell.parseName(lbName).name || EMPTY_NAME,
          });
        }
        drawLeaderboard();
        break;
      }
      case 0x32: {
        // pie chart
        leaderboard.items = [];
        leaderboard.type = "pie";

        const teamsCount = reader.getUint32();
        for (let i = 0; i < teamsCount; ++i) {
          leaderboard.items.push(reader.getFloat32());
        }
        drawLeaderboard();
        break;
      }
      case 0x40: {
        // set border
        border.left = reader.getFloat64();
        border.top = reader.getFloat64();
        border.right = reader.getFloat64();
        border.bottom = reader.getFloat64();
        border.width = border.right - border.left;
        border.height = border.bottom - border.top;
        border.centerX = (border.left + border.right) / 2;
        border.centerY = (border.top + border.bottom) / 2;
        if (event.data.byteLength === 33) break;
        if (!mapCenterSet) {
          mapCenterSet = true;
          camera.x = camera.target.x = border.centerX;
          camera.y = camera.target.y = border.centerY;
          camera.scale = camera.target.scale = 1;
        }
        reader.getUint32(); // game type
        if (stats.pingLoopId) break;
        stats.pingLoopId = setInterval(() => {
          wsSend(UINT8_CACHE[254]);
          stats.pingLoopStamp = Date.now();
        }, 1000);
        break;
      }
      case 0x63: {
        // chat message
        const flagMask = reader.getUint8();
        const flags = {
          server: !!(flagMask & 0x80),
          admin: !!(flagMask & 0x40),
          mod: !!(flagMask & 0x20),
        };
        const color = new Color(
          reader.getUint8(),
          reader.getUint8(),
          reader.getUint8()
        );
        const rawName = reader.getStringUTF8();
        const message = reader.getStringUTF8();

        let name = Cell.parseName(rawName).name || EMPTY_NAME;

        if (flags.server && name !== "SERVER") name = `[SERVER]`;
        if (flags.admin) name = `[ADMIN] ${name}`;
        if (flags.mod) name = `[MOD] ${name}`;

        const wait = Math.max(3000, 1000 + message.length * 150);
        chat.waitUntil =
          syncUpdStamp - chat.waitUntil > 1000
            ? syncUpdStamp + wait
            : chat.waitUntil + wait;
        chat.messages.push({
          color,
          name,
          message,
          time: syncUpdStamp,
          server: flags.server,
          admin: flags.admin,
          mod: flags.mod,
        });
        if (settings.showChat) drawChat();
        break;
      }
      case 0xfe: {
        // server stat
        stats.info = JSON.parse(reader.getStringUTF8());
        stats.latency = syncUpdStamp - stats.pingLoopStamp;
        drawStats();
        break;
      }
      default: {
        // invalid packet
        console.warn("Invalid Packet has been sent, closing the Websocket Connection");
        ws.close();
        break;
      }
     }
	}
   }
  function sendMouseMove(x, y) {
    const writer = new Writer(true);
    writer.setUint8(0x10);
    writer.setUint32(x);
    writer.setUint32(y);
    writer._b.push(0, 0, 0, 0);
    wsSend(writer);
  }
  function sendPlay(name, skin) {
    const writer = new Writer(true);
    writer.setUint8(0x00); // Packet ID for play
    writer.setStringUTF8(name); // Send name
    writer.setStringUTF8(skin); // Send skin
    wsSend(writer);
  }
  function sendChat(text) {
    const writer = new Writer();
    writer.setUint8(0x63);
    writer.setUint8(0);
    writer.setStringUTF8(text);
    wsSend(writer);
  }

  function gameReset() {
    cleanupObject(cells);
    cleanupObject(border);
    cleanupObject(leaderboard);
    cleanupObject(chat);
    cleanupObject(stats);
    chat.messages = [];
    leaderboard.items = [];
    cells.mine = [];
    cells.byId = new Map();
    cells.list = [];
    camera.x = camera.y = camera.target.x = camera.target.y = 0;
    camera.scale = camera.target.scale = 1;
    mapCenterSet = false;
  }

  const cells = {
    mine: [],
    byId: new Map(),
    list: [],
  };
  const border = {
    left: -2000,
    right: 2000,
    top: -2000,
    bottom: 2000,
    width: 4000,
    height: 4000,
    centerX: -1,
    centerY: -1,
  };
  const leaderboard = Object.create({
    type: null,
    items: null,
    canvas: document.createElement("canvas"),
    teams: ["#F33", "#3F3", "#33F"],
  });
  const chat = Object.create({
    messages: [],
    waitUntil: 0,
    canvas: document.createElement("canvas"),
    visible: false,
  });
  const stats = Object.create({
    fps: 0,
    timePerFrame: 0,
    latency: NaN,
    supports: null,
    info: null,
    pingLoopId: NaN,
    pingLoopStamp: null,
    canvas: document.createElement("canvas"),
    visible: false,
    score: NaN,
    maxScore: 0,
    playerCells: 0,
  });

  const knownSkins = new Map();
  const loadedSkins = new Map();
  const macroCooldown = 1000 / 7;
  const camera = {
    x: 0,
    y: 0,
    target: {
      x: 0,
      y: 0,
      scale: 1,
    },
    viewportScale: 1,
    userZoom: 0.8,
    sizeScale: 1,
    scale: 1,
  };

  let wsUrl = WEBSOCKET_URL;
  let ws = null;
  let reconnectDelay = 1000;

  let syncUpdStamp = Date.now();
  let syncAppStamp = Date.now();

  let mainCanvas = null;
  let mainCtx = null;
  let soundsVolume;
  let escOverlayShown = false;
  let isTyping = false;
  let chatBox = null;
  let mapCenterSet = false;
  let minionControlled = false;
  let freeRoam = false;
  let playerRoam = false; //Spectating a specific player.
  let mouseX = NaN;
  let mouseY = NaN;
  let macroIntervalID;
  let quadtree;
  let minimapnodes = [];

  const settings = {
    nick: "",
    skin: "",
    gamemode: "",
    showSkins: true,
    showNames: true,
    darkTheme: false,
    showColor: true,
    showMass: false,
    _showChat: true,
    get showChat() {
      return this._showChat;
    },
    set showChat(a) {
      this._showChat = a;
      if (!chatBox) return;
      a ? chatBox.show() : chatBox.hide();
    },
    showMinimap: true,
    showPosition: false,
    showBorder: false,
    showGrid: true,
    playSounds: false,
    soundsVolume: 0.5,
    moreZoom: false,
    fillSkin: true,
    backgroundSectors: false,
    jellyPhysics: false,
    strokeCell: true // request in discord
  };
  const pressed = {
    " ": false,
    w: false,
    e: false,
    r: false,
    t: false,
    p: false,
    q: false,
    enter: false,
    escape: false,
    o: false,
    m: false,
    i: false,
    y: false,
    u: false,
    k: false,
    l: false,
    h: false,
    z: false,
    x: false,
    s: false,
    c: false,
    g: false,
    j: false,
    b: false,
    v: false,
    n: false,
  };

  const eatSound = new Sound("./assets/static/sound/eat.mp3", 0.5, 10);
  const pelletSound = new Sound("./assets/static/sound/pellet.mp3", 0.5, 10);

  fetch("./assets/static/skinList.txt")
    .then((resp) => resp.text())
    .then((data) => {
      const skins = data.split(",").filter((name) => name.length > 0);
      if (skins.length === 0) return;
      byId("gallery-btn").style.display = "flex";
      const stamp = Date.now();
      for (const skin of skins) knownSkins.set(skin, stamp);
      for (const i of knownSkins.keys()) {
        if (knownSkins.get(i) !== stamp) knownSkins.delete(i);
      }
    });

  function hideESCOverlay() {
    escOverlayShown = false;
    var UI = document.getElementById("UI");
    var overlays = document.getElementById("overlays");
    var footer = document.getElementById("footer");
    var reCaptcha = document.querySelector(".custom-recaptcha-badge");
    reCaptcha.style.display = "none";
    overlays.style.display = "none";
    footer.style.display = "none";
    UI.classList.add("fade-out-zoom-in");
    var snowflakes = document.getElementsByClassName("snowflake");

    for (var i = 0; i < snowflakes.length; i++) {
      snowflakes[i].style.opacity = "0";
    }

    if (!zIndexChanged) {
      zIndexChanged = true;
      setTimeout(function () {
        UI.style.zIndex = "-2";
      }, 1000);
    } else {
      UI.style.zIndex = "-2";
    }
  }

  function showESCOverlay() {
    escOverlayShown = true;
    var UI = document.getElementById("UI");
    var overlays = document.getElementById("overlays");
    overlays.style.display = "flex";
    UI.style.zIndex = "200";
  }

  function toCamera(ctx) {
    ctx.translate(mainCanvas.width / 2, mainCanvas.height / 2);
    scaleForth(ctx);
    ctx.translate(-camera.x, -camera.y);
  }
  function scaleForth(ctx) {
    ctx.scale(camera.scale, camera.scale);
  }
  function scaleBack(ctx) {
    ctx.scale(1 / camera.scale, 1 / camera.scale);
  }
  function fromCamera(ctx) {
    ctx.translate(camera.x, camera.y);
    scaleBack(ctx);
    ctx.translate(-mainCanvas.width / 2, -mainCanvas.height / 2);
  }

  function initSetting(id, elm) {
    function simpleAssignListen(id, elm, prop) {
      if (settings[id] !== "") elm[prop] = settings[id];
      elm.addEventListener("change", () => {
        settings[id] = elm[prop];
      });
    }
    switch (elm.tagName.toLowerCase()) {
      case "input":
        switch (elm.type.toLowerCase()) {
          case "range":
          case "text":
            simpleAssignListen(id, elm, "value");
            break;
          case "checkbox":
            simpleAssignListen(id, elm, "checked");
            break;
        }
        break;
      case "select":
        simpleAssignListen(id, elm, "value");
        break;
    }
  }
  function loadSettings() {
    const text = localStorage.getItem("settings");
    const obj = text ? JSON.parse(text) : settings;
    for (const prop in settings) {
      const elm = byId(prop.charAt(0) === "_" ? prop.slice(1) : prop);
      if (elm) {
        if (Object.hasOwnProperty.call(obj, prop)) settings[prop] = obj[prop];
        initSetting(prop, elm);
      } else
        Logger.info(
          `setting ${prop} not loaded because there is no element for it.`
        );
    }
  }
  function storeSettings() {
    localStorage.setItem("settings", JSON.stringify(settings));
  }
  function buildGallery() {
    const sortedSkins = Array.from(knownSkins.keys()).sort();
    let c = "";

    const selectedSkinSection = `
		<div class="selected-skin-container">
		<h4>Selected Skin:</h4>
			<div class="skin">
				<img id="selectedSkinImg" class="circular" src="./assets/static/img/defaultSkin.png">
				<h4 id="selectedSkinName" class="skinName">Default Skin</h4>
			</div>
			<div class="coin-info">
				<img src="/assets/static/img/coin.webp" alt="Coin Icon" class="coin-icon">
				<span id="coinCount">0</span>
			</div>
			<div class="remove-skin-btn" onclick="removeSelectedSkin()">
            Remove Skin
        </div>
		</div>
	`;
    for (const skin of sortedSkins) {
      if (parseInt(skin) >= 1 && parseInt(skin) <= 20) continue; // Skip bot skins that are named 1-20
      c += `<li class="skin" onclick="changeSkin('${skin}')">`;
      c += `<img class="circular" src="./assets/static/skins/${skin}.png">`;
      c += `<h4 class="skinName">${skin}</h4>`;
      c += "</li>";
    }

    byId("gallery-body").innerHTML =
      selectedSkinSection + `<ul id="skinsUL">${c}</ul>`;
  }

let isDragging = false;
let startY;
let startScrollTop;

const chatContainer = document.getElementById("chatContainer");
const scrollbarThumb = document.getElementById("chatScrollbarThumb");

chatContainer.addEventListener("scroll", adjustScrollbar);

scrollbarThumb.addEventListener("mousedown", function(e) {
    isDragging = true;
    startY = e.pageY;
    startScrollTop = chatContainer.scrollTop;
    document.body.classList.add("no-select");
});

document.addEventListener("mousemove", function(e) {
    if (!isDragging) return;
    const deltaY = e.pageY - startY;
    const scrollRatio = deltaY / (chatContainer.parentNode.offsetHeight - scrollbarThumb.offsetHeight);
    chatContainer.scrollTop = startScrollTop + scrollRatio * (chatContainer.scrollHeight - chatContainer.offsetHeight);
    adjustScrollbar(); 
});

document.addEventListener("mouseup", function() {
    if (!isDragging) return;
    isDragging = false;
    document.body.classList.remove("no-select");
});

scrollbarThumb.addEventListener("dragstart", function(e) {
    e.preventDefault();
});

function adjustScrollbar() {
    const visibleRatio = chatContainer.offsetHeight / chatContainer.scrollHeight;
    const thumbHeight = Math.max(visibleRatio * chatContainer.parentNode.offsetHeight, 20); 
    scrollbarThumb.style.height = `${thumbHeight}px`;

    const thumbTop = (chatContainer.scrollTop / (chatContainer.scrollHeight - chatContainer.offsetHeight)) * (chatContainer.parentNode.offsetHeight - thumbHeight);
    scrollbarThumb.style.top = `${thumbTop}px`;
}
  function drawChat() {
    if (chat.messages.length === 0 || !settings.showChat) {
        chat.visible = false;
        let chatMessages = document.getElementsByClassName("chat-message");
        for (let i = 0; i < chatMessages.length; i++) {
            chatMessages[i].style.display = "none"; 
        }
        document.getElementById("chat_textbox").style.display = "none";
        document.getElementById("chatContainer").style.display = "none"; 
        return;
    }

    chat.visible = true;
    document.getElementById("chatContainer").style.display = "block";
    document.getElementById("chat_textbox").style.display = "inline-block";
    const chatContainer = document.getElementById("chatContainer");
    chatContainer.innerHTML = "";

    const latestMessages = chat.messages.slice(-60);

    for (let i = 0; i < latestMessages.length; i++) {
        const message = latestMessages[i].message;
        const name = latestMessages[i].name;
        const color = latestMessages[i].color;

        let messageDiv = document.createElement("div");
        messageDiv.className = "chat-message";

        let nameSpan = document.createElement("span");
        nameSpan.textContent = name + ": ";
        nameSpan.style.color = color.toHex();
        messageDiv.appendChild(nameSpan);

        let textSpan = document.createElement("span");
        textSpan.textContent = message;
        textSpan.style.color = settings.darkTheme ? "#FFF" : "#000";
        messageDiv.appendChild(textSpan);

        chatContainer.appendChild(messageDiv);
    }

    adjustScrollbar();

    chatContainer.scrollTop = chatContainer.scrollHeight;
}

function adjustScrollbar() {
    const chatContainer = document.getElementById("chatContainer");
    const scrollbarThumb = document.getElementById("chatScrollbarThumb");
    const visibleRatio = chatContainer.offsetHeight / chatContainer.scrollHeight;
    const thumbHeight = Math.max(visibleRatio * chatContainer.parentNode.offsetHeight, 20);
    scrollbarThumb.style.height = thumbHeight + "px";

    const scrollPercentage = chatContainer.scrollTop / (chatContainer.scrollHeight - chatContainer.offsetHeight);
    scrollbarThumb.style.top = (scrollPercentage * (chatContainer.parentNode.offsetHeight - thumbHeight)) + "px";
}



  // Event listeners
  document.addEventListener("DOMContentLoaded", () => {
    loadKeybindings();
    const darkThemeCheckbox = document.getElementById("darkTheme");
    const showChatCheckbox = document.getElementById("showChat");

    darkThemeCheckbox.addEventListener("click", () => {
      settings.darkTheme = darkThemeCheckbox.checked;
      drawChat(); // Redraw chat to apply dark theme changes
      drawStats();
    });

    showChatCheckbox.addEventListener("click", () => {
      settings.showChat = showChatCheckbox.checked;
      drawChat(); // Redraw chat to show/hide chat based on the setting
    });
  });
  window.addEventListener("DOMContentLoaded", () => {
    const emojiButton = document.getElementById("emoji-button");
    const chatTextbox = document.getElementById("chat_textbox");

    EmojiButton(emojiButton, function (emoji) {
      chatTextbox.value += emoji; // Append emoji to the chat textbox
    });
  });

  document.getElementById("button1").onclick = function () {
    const settingsSelector = document.getElementById("settings-selector");
    const settingsBody = document.getElementById("settings-body");
    const controls = document.getElementById("controls");
    controls.style.pointerEvents = "none";
    controls.classList.remove("fadeIn");
    controls.classList.add("fadeOut");
    settingsBody.style.pointerEvents = "auto";
    settingsBody.classList.remove("fadeOut");
    settingsBody.classList.add("fadeIn");
    settingsSelector.classList.remove("slideLeft");
    settingsSelector.classList.add("slideRight");
  };
  document.getElementById("button2").onclick = function () {
    const settingsSelector = document.getElementById("settings-selector");
    const settingsBody = document.getElementById("settings-body");
    const controls = document.getElementById("controls");
    settingsBody.style.pointerEvents = "none";
    settingsBody.classList.remove("fadeIn");
    settingsBody.classList.add("fadeOut");
    controls.style.pointerEvents = "auto";
    controls.classList.add("fadeIn");
    controls.classList.remove("fadeOut");
    settingsSelector.classList.remove("slideRight");
    settingsSelector.classList.add("slideLeft");
  };

  function drawStats() {
    const statsContainer = document.getElementById("statsContainer");
    const textColor = settings.darkTheme ? "white" : "black";

    if (!stats.info /*|| !settings.showStats*/) {
      stats.visible = false;
      statsContainer.style.display = "none";
      return;
  }

    let scoreDiv = document.getElementById("scoreStat");
    if (!scoreDiv) {
        scoreDiv = document.createElement("div");
        scoreDiv.id = "scoreStat";
        statsContainer.appendChild(scoreDiv);
    }
    scoreDiv.style.color = textColor;
    scoreDiv.textContent = `Score: ${stats.score || 0}`;

    let fpsPingDiv = document.getElementById("fpsPingStat");
    if (!fpsPingDiv) {
        fpsPingDiv = document.createElement("div");
        fpsPingDiv.id = "fpsPingStat";
        statsContainer.appendChild(fpsPingDiv);
    }
    fpsPingDiv.style.color = textColor;
    fpsPingDiv.textContent = `${~~stats.fps} FPS ${isNaN(stats.latency) ? "" : ` ${stats.latency}ms ping`}`;
    //fpsPingDiv.textContent = `${~~stats.fps} FPS ${Math.round(stats.timePerFrame)} ms TPF ${isNaN(stats.latency) ? "" : ` ${stats.latency}ms ping`}`;

    stats.visible = true;
    statsContainer.style.display = "block";
    const defaultTextColor = settings.darkTheme ? "white" : "black";

    const serverStats = [
        { text: `${stats.info.name}` + ` - ${stats.info.mode}`, id: "serverNameMode" },
        //{ text: `${stats.info.playersTotal} / ${stats.info.playersLimit} players`, id: "playerCount" },
        { text: `${stats.info.playersAlive} playing`, id: "playersPlaying" },
        { text: `${stats.info.playersSpect} spectating`, id: "playersSpectating" },
        { text: `Cell count: ${stats.info.playerCells}`, id: "playerCells", textColor: stats.info.playerCells === 16 ? "red" : defaultTextColor},
        { text: `${stats.info.gameState??""}`, id: "gameState" }
    ];

    serverStats.forEach(stat => {
        let statDiv = document.getElementById(stat.id);
        if (!statDiv) {
            statDiv = document.createElement("div");
            statDiv.id = stat.id;
            statsContainer.appendChild(statDiv);
        }
        statDiv.style.color = stat.textColor || defaultTextColor;
        statDiv.textContent = stat.text;
    });
}

  function drawPosition() {
    if (border.centerX !== 0 || border.centerY !== 0 || !settings.showPosition)
      return;

    // Scale the width and height
    const width = 200 * (border.width / border.height);
    const height = 40 * (border.height / border.width);

    let beginX = mainCanvas.width / camera.viewportScale - width;
    let beginY = mainCanvas.height / camera.viewportScale - height;

    if (settings.showMinimap) {
      mainCtx.font = `15px Ubuntu`;
      beginX += width / 2 - 1;
      beginY = beginY - (194 * border.height) / border.width;
      mainCtx.textAlign = "right";
      mainCtx.fillStyle = settings.darkTheme ? "#AAA" : "#555";
      mainCtx.fillText(
        `X: ${~~camera.x}, Y: ${~~camera.y}`,
        beginX + width / 2,
        beginY + height / 2
      );
    } else {
      mainCtx.fillStyle = "#000";
      mainCtx.globalAlpha = 0.4;
      mainCtx.fillRect(beginX, beginY, width, height);
      mainCtx.globalAlpha = 1;
      drawRaw(
        mainCtx,
        beginX + width / 2,
        beginY + height / 2,
        `X: ${~~camera.x}, Y: ${~~camera.y}`
      );
    }
  }

  /*function prettyPrintTime(seconds) {
    const minutes = ~~(seconds / 60);
    if (minutes < 1) return "<1 min";
    const hours = ~~(minutes / 60);
    if (hours < 1) return `${minutes}min`;
    const days = ~~(hours / 24);
    if (days < 1) return `${hours}h`;
    return `${days}d`;
  }*/
  function drawLeaderboard() {
    const scale = 2; // Scale factor (2 for double resolution)
    const canvas = document.getElementById("pieChartCanvas");
    canvas.width = 200 * scale; // Original width * scale
    canvas.height = 240 * scale; // Original height * scale
    const leaderboardDiv = document.querySelector(".leaderboard .positions");

    // Check if the mode is FFA or Team Mode
    if (leaderboard.type === "pie") {
      // Team Mode: Show pie chart and update team positions
      canvas.style.display = "block"; // Show pie chart
      drawPieChart(leaderboard);

      // Clear and update team positions
      leaderboardDiv.innerHTML = "";
    } else {
      // FFA Mode: Hide pie chart and show player leaderboard
      canvas.style.display = "none"; // Hide pie chart

      // Update FFA leaderboard
      leaderboardDiv.innerHTML = "";
      for (let i = 0; i < leaderboard.items.length; i++) {
        let text,
          isMe = false;
        if (leaderboard.type === "text") {
          text = leaderboard.items[i];
        } else {
          text = leaderboard.items[i].name;
          isMe = leaderboard.items[i].me;
        }
        const positionDiv = document.createElement("div");
        positionDiv.className = "position";
        positionDiv.style.color = isMe ? "#FAA" : "#FFF";
        positionDiv.textContent = `${i + 1}. ${text}`;
        leaderboardDiv.appendChild(positionDiv);
      }
    }
  }
  function drawPieChart(leaderboardData) {
    const scale = 2; // Scale factor (2 for double resolution)
    const canvas = document.getElementById("pieChartCanvas");
    const ctx = canvas.getContext("2d");
    ctx.scale(scale, scale);

    let last = 0;
    for (let i = 0; i < leaderboardData.items.length/2; i++) {
      ctx.fillStyle = leaderboardData.teams[i];
      ctx.beginPath();
      ctx.moveTo(100, 100); // Center of the canvas
      ctx.arc(
        100,
        100,
        80,
        last,
        (last += leaderboardData.items[i] * Math.PI * 2),
        false
      );
      ctx.closePath();
      ctx.fill();
    }
    last = 0;
    for (let i = 0; i < leaderboardData.items.length/2; i++) {
      ctx.strokeStyle = leaderboardData.teams[i];
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.arc(
        100,
        100,
        86,
        last,
        (last += leaderboardData.items[i+leaderboardData.items.length/2] * Math.PI * 2),
        false
      );
      ctx.stroke();
    }
    
  }
  function drawGrid() {
    mainCtx.save();
    mainCtx.lineWidth = 1;
    mainCtx.strokeStyle = settings.darkTheme ? "#AAA" : "#000";
    mainCtx.globalAlpha = 0.2;
    const step = 50;
    const cW = mainCanvas.width / camera.scale;
    const cH = mainCanvas.height / camera.scale;
    const startLeft = (-camera.x + cW / 2) % step;
    const startTop = (-camera.y + cH / 2) % step;

    scaleForth(mainCtx);
    mainCtx.beginPath();
    for (let i = startLeft; i < cW; i += step) {
      mainCtx.moveTo(i, 0);
      mainCtx.lineTo(i, cH);
    }
    for (let i = startTop; i < cH; i += step) {
      mainCtx.moveTo(0, i);
      mainCtx.lineTo(cW, i);
    }
    mainCtx.stroke();
    mainCtx.restore();
  }
  function drawBackgroundSectors() {
    if (border === undefined || border.width === undefined) return;
    mainCtx.save();

    const sectorCount = 5;
    const sectorNames = ["ABCDE", "12345"];
    const w = border.width / sectorCount;
    const h = border.height / sectorCount;

    toCamera(mainCtx);
    mainCtx.fillStyle = settings.darkTheme ? "#666" : "#DDD";
    mainCtx.textBaseline = "middle";
    mainCtx.textAlign = "center";
    mainCtx.font = `${(w / 3) | 0}px Ubuntu`;

    for (let y = 0; y < sectorCount; ++y) {
      for (let x = 0; x < sectorCount; ++x) {
        const str = sectorNames[0][x] + sectorNames[1][y];
        const dx = (x + 0.5) * w + border.left;
        const dy = (y + 0.5) * h + border.top;
        mainCtx.fillText(str, dx, dy);
      }
    }
    mainCtx.restore();
  }
  function drawMinimap() {
    if (border.centerX !== 0 || border.centerY !== 0 || !settings.showMinimap)
      return;
    mainCtx.save();
    mainCtx.resetTransform();
    const targetSize = 200;
    const borderAR = border.width / border.height; // aspect ratio
    const width = targetSize * borderAR * camera.viewportScale;
    const height = (targetSize / borderAR) * camera.viewportScale;
    const beginX = mainCanvas.width - width;
    const beginY = mainCanvas.height - height;

    mainCtx.fillStyle = "#000";
    mainCtx.globalAlpha = 0.4;
    mainCtx.fillRect(beginX, beginY, width, height);
    mainCtx.globalAlpha = 1;

    const sectorCount = 5;
    const sectorNames = ["ABCDE", "12345"];
    const sectorWidth = width / sectorCount;
    const sectorHeight = height / sectorCount;
    const sectorNameSize = Math.min(sectorWidth, sectorHeight) / 3;
    let textSize = sectorNameSize / 1.4; //Size of player names on the minimap.

    mainCtx.fillStyle = settings.darkTheme ? "#666" : "#DDD";
    mainCtx.textBaseline = "middle";
    mainCtx.textAlign = "center";
    mainCtx.font = `${sectorNameSize}px Ubuntu`;

    for (let i = 0; i < sectorCount; i++) {
      const x = (i + 0.5) * sectorWidth;
      for (let j = 0; j < sectorCount; j++) {
        const y = (j + 0.5) * sectorHeight;
        mainCtx.fillText(
          sectorNames[0][i] + sectorNames[1][j],
          beginX + x,
          beginY + y
        );
      }
    }

    const xScale = width / border.width;
    const yScale = height / border.height;
    const halfWidth = border.width / 2;
    const halfHeight = border.height / 2;
    const myPosX = beginX + (camera.x + halfWidth) * xScale;
    const myPosY = beginY + (camera.y + halfHeight) * yScale;

    const xIndex = ((myPosX - beginX) / sectorWidth) | 0;
    const yIndex = ((myPosY - beginY) / sectorHeight) | 0;
    const lightX = beginX + xIndex * sectorWidth;
    const lightY = beginY + yIndex * sectorHeight;
    mainCtx.fillStyle = "yellow";
    mainCtx.globalAlpha = 0.3;
    mainCtx.fillRect(lightX, lightY, sectorWidth, sectorHeight);
    mainCtx.globalAlpha = 1;

    //draw other players onto the minimap
    var posX,posY = null;
    for(let x = 0; x < minimapnodes.length; x++){
      //console.log(minimapnodes[x]);


      //normalize the co-ordinates
    let  playerposX = minimapnodes[x].positionX;
    let  playerposY = minimapnodes[x].positionY;

      // let playerdotx = (playerposX < 0) ? Math.abs(playerposX) : playerposX + border.right;
      // let playerdotY= (playerposY < 0) ? Math.abs(playerposY) : playerposY+ border.top;
      //
      var playerPosX = beginX + ((playerposX + border.width / 2) / border.width * width);
      var playerPosY = beginY + ((playerposY + border.height / 2) / border.height * height);


      mainCtx.fillStyle = minimapnodes[x].color.toHex();
      mainCtx.beginPath();
      mainCtx.arc(playerPosX,playerPosY, calculatePlayerDotSize(minimapnodes[x].size), 0, PI_2, false);
      mainCtx.closePath();
      mainCtx.fill();

      mainCtx.fillStyle = settings.darkTheme ? "#DDD" : "#222";
      mainCtx.font = `${textSize}px Ubuntu`;
      mainCtx.fillText(minimapnodes[x].name,playerPosX, playerPosY - 7 - textSize / 2);

    }
     mainCtx.restore();
  }
  function calculatePlayerDotSize(size){
    //option 1: y = 5768x – 20752
    //option 2: y = 4085x – 14020
    //option 3: y = 5298x - 18871 *implement this one

    return (size + 18871) / 5298;
  }

  function drawBorders() {
    if (!settings.showBorder) return;
    mainCtx.strokeStyle = "#0000ff";
    mainCtx.lineWidth = 20;
    mainCtx.lineCap = "round";
    mainCtx.lineJoin = "round";
    mainCtx.beginPath();
    mainCtx.moveTo(border.left, border.top);
    mainCtx.lineTo(border.right, border.top);
    mainCtx.lineTo(border.right, border.bottom);
    mainCtx.lineTo(border.left, border.bottom);
    mainCtx.closePath();
    mainCtx.stroke();
  }

  function drawGame() {
    stats.fps += (1000 / Math.max(Date.now() - syncAppStamp, 1) - stats.fps) / 10;
    stats.fps = Math.round(stats.fps);
    syncAppStamp = Date.now();

    const drawList = cells.list.slice(0).sort(cellSort);
    for (const cell of drawList) cell.update(syncAppStamp);
    cameraUpdate();
    if (settings.jellyPhysics) {
      updateQuadtree();
      for (const cell of drawList) {
        if(cell.size < 60) continue; //Check if this will work
        cell.updateNumPoints();
        cell.movePoints();
      }
    }

    mainCanvas.width = window.innerWidth;
    mainCanvas.height = window.innerHeight;

    mainCtx.save();
    mainCtx.resetTransform();

    mainCtx.fillStyle = settings.darkTheme ? "#111" : "#F2FBFF";
    mainCtx.fillRect(0, 0, mainCanvas.width, mainCanvas.height);
    if (settings.showGrid) drawGrid();
    if (settings.backgroundSectors) drawBackgroundSectors();

    toCamera(mainCtx);
    drawBorders();

    for (const cell of drawList) cell.draw(mainCtx);

    fromCamera(mainCtx);
    quadtree = null;
    mainCtx.scale(camera.viewportScale, camera.viewportScale);

    drawStats();
    if (leaderboard.visible) {
      const scaledLeaderboardWidth = leaderboard.canvas.width;
      const scaledLeaderboardHeight = leaderboard.canvas.height;

      // Adjusting the x position to account for the new width
      const xPos =
        mainCanvas.width / camera.viewportScale - 10 - scaledLeaderboardWidth;
      const yPos = 10;

      mainCtx.drawImage(
        leaderboard.canvas,
        xPos,
        yPos,
        scaledLeaderboardWidth,
        scaledLeaderboardHeight
      );
    }
    if (settings.showChat && (chat.visible || isTyping)) {
      mainCtx.globalAlpha = isTyping
        ? 1
        : Math.max(1000 - syncAppStamp + chat.waitUntil, 0) / 1000;

      const scaledChatWidth = chat.canvas.width;
      const scaledChatHeight = chat.canvas.height;

      const xPos = 10 / camera.viewportScale;
      const yPosAdjustment = 4;
      const yPos =
        (mainCanvas.height - 55) / camera.viewportScale -
        scaledChatHeight -
        yPosAdjustment;

      mainCtx.drawImage(
        chat.canvas,
        xPos,
        yPos,
        scaledChatWidth,
        scaledChatHeight
      );

      mainCtx.globalAlpha = 1;
    }
    drawMinimap();
    drawPosition();

    mainCtx.restore();

    if (cells.mine.length <= 0) {
      /*if (minionControlled) {
        mainCtx.save();
        mainCtx.font = `18px Ubuntu`;
        mainCtx.textAlign = "center";
        mainCtx.textBaseline = "hanging";
        mainCtx.fillStyle = "#eea236";
        const text = "You are controlling a minion, press Q to switch back.";
        mainCtx.fillText(text, mainCanvas.width / 2, 5);
        mainCtx.restore();
      }*/
      if (spectateDefault && !freeRoam && !playerRoam /*&& players*/) {
        mainCtx.save();
        mainCtx.font = `18px Ubuntu`;
        mainCtx.textAlign = "center";
        mainCtx.textBaseline = "hanging";
        mainCtx.fillStyle = "#eea236";
        const text =
          "You are now spectating the largest player, press Q to enable free roam.";
        mainCtx.fillText(text, mainCanvas.width / 2, 5);
        mainCtx.restore();
      }
       if (!spectateDefault && freeRoam && !playerRoam /*&& players*/) {
        mainCtx.save();
        mainCtx.font = `18px Ubuntu`;
        mainCtx.textAlign = "center";
        mainCtx.textBaseline = "hanging";
        mainCtx.fillStyle = "#eea236";
        const text =
          "You are now in free roam, press Q to spectate the largest player.";
        mainCtx.fillText(text, mainCanvas.width / 2, 5);
        mainCtx.restore();
       }
      if (!freeRoam && !spectateDefault && playerRoam /*&& players*/) {
        mainCtx.save();
        const fontSize = 18;
        mainCtx.font = `${fontSize}px Ubuntu`;
        mainCtx.textAlign = "center";
        mainCtx.textBaseline = "hanging";
        mainCtx.fillStyle = "#eea236";
        const text1 =
          "To switch to another player, click space.";
        const text2 = "Press Q to switch back to free roam.";
        mainCtx.fillText(text1, mainCanvas.width / 2, 5);

        const verticalSpacing = 10;
        const text2Y = 5 + fontSize + verticalSpacing;
        mainCtx.fillText(text2, mainCanvas.width / 2, text2Y);
        mainCtx.restore();
      }
    }

    cacheCleanup();

    stats.timePerFrame = (Date.now() -syncAppStamp + stats.timePerFrame * 9) / 10;

    window.requestAnimationFrame(drawGame);
  }

  function cellSort(a, b) {
    return a.s === b.s ? a.id - b.id : a.s - b.s;
  }

  function cameraUpdate() {
    const myCells = [];
    for (const id of cells.mine) {
      const cell = cells.byId.get(id);
      if (cell) myCells.push(cell);
    }
    if (myCells.length > 0) {
      let x = 0;
      let y = 0;
      let s = 0;
      let score = 0;
      for (const cell of myCells) {
        score += ~~((cell.ns * cell.ns) / 100);
        x += cell.x;
        y += cell.y;
        s += cell.s;
      }
      camera.target.x = x / myCells.length;
      camera.target.y = y / myCells.length;
      camera.sizeScale = Math.pow(Math.min(64 / s, 1), 0.4);
      camera.target.scale = camera.sizeScale;
      camera.target.scale *= camera.viewportScale * camera.userZoom;
      camera.x = (camera.target.x + camera.x) / 2;
      camera.y = (camera.target.y + camera.y) / 2;
      stats.score = score;
      stats.maxScore = Math.max(stats.maxScore, score);
    } else {
      stats.score = NaN;
      stats.maxScore = 0;
      camera.x += (camera.target.x - camera.x) / 20;
      camera.y += (camera.target.y - camera.y) / 20;
    }
    camera.scale += (camera.target.scale - camera.scale) / 9;
  }
  function sqDist(a, b) {
    return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y);
  }
  function updateQuadtree() {
    const w = 1920 / camera.sizeScale;
    const h = 1080 / camera.sizeScale;
    const x = camera.x - w / 2;
    const y = camera.y - h / 2;
    quadtree = new window.PointQuadTree(x, y, w, h, QUADTREE_MAX_POINTS);
    for (const cell of cells.list) {
      for (const point of cell.points) quadtree.insert(point);
    }
  }

  class Cell {
    static parseName(value) {
      // static method
      let [, skin, name] = /^(?:<([^}]*)>)?([^]*)/.exec(value || "");
      name = name.trim();
      return {
        name: name,
        skin: (skin || "").trim() || name,
      };
    }
    constructor(id, x, y, s, name, color, skin, flags) {
      this.destroyed = false;
      this.diedBy = 0;
      this.nameSize = 0;
      this.drawNameSize = 0;
      this.updated = null;
      this.dead = null;
      this.id = id;
      this.ox = x;
      this.x = x;
      this.nx = x;
      this.oy = y;
      this.y = y;
      this.ny = y;
      this.os = s;
      this.s = s;
      this.ns = s;
      this.setColor(color);
      this.setName(name);
      this.setSkin(skin);
      this.isVirus = flags.isVirus;
      this.isEjectedMass = flags.isEjectedMass;
      this.isPellet = flags.isPellet;
      this.born = syncUpdStamp;
      this.points = [];
      this.pointsVel = [];
    }
    destroy(killerId) {
      cells.byId.delete(this.id);
      if (cells.mine.remove(this.id) && cells.mine.length === 0)
        showESCOverlay();
      this.destroyed = true;
      this.dead = syncUpdStamp;
      if (killerId && !this.diedBy) {
        this.diedBy = killerId;
        this.updated = syncUpdStamp;
      }
    }
    update(relativeTime) {
      const prevFrameSize = this.s;
      const dt = Math.max(Math.min((relativeTime - this.updated) / 120, 1), 0);
      let diedBy;
      if (this.destroyed && Date.now() > this.dead + 200) {
        cells.list.remove(this);
      } else if (this.diedBy && (diedBy = cells.byId.get(this.diedBy))) {
        this.nx = diedBy.x;
        this.ny = diedBy.y;
      }
      this.x = this.ox + (this.nx - this.ox) * dt;
      this.y = this.oy + (this.ny - this.oy) * dt;
      this.s = this.os + (this.ns - this.os) * dt;
      this.nameSize = ~~(~~Math.max(~~(0.3 * this.ns), 24) / 3) * 3;
      this.drawNameSize = ~~(~~Math.max(~~(0.3 * this.s), 24) / 3) * 3;

      if (settings.jellyPhysics) {
        const ratio = this.s / prevFrameSize;
        if (this.ns != this.os && ratio != 1) {
          for (const point of this.points) point.rl *= ratio;
        }
      }
    }
    updateNumPoints() {
      let numPoints = Math.min(
        Math.max((this.s * camera.scale) | 0, CELL_POINTS_MIN),
        CELL_POINTS_MAX
      );
      if (this.isVirus) numPoints = VIRUS_POINTS;
      while (this.points.length > numPoints) {
        const i = (Math.random() * this.points.length) | 0;
        this.points.splice(i, 1);
        this.pointsVel.splice(i, 1);
      }
      if (this.points.length === 0 && numPoints !== 0) {
        this.points.push({
          x: this.x,
          y: this.y,
          rl: this.s,
          parent: this,
        });
        this.pointsVel.push(Math.random() - 0.5);
      }
      while (this.points.length < numPoints) {
        const i = (Math.random() * this.points.length) | 0;
        const point = this.points[i];
        const vel = this.pointsVel[i];
        this.points.splice(i, 0, {
          x: point.x,
          y: point.y,
          rl: point.rl,
          parent: this,
        });
        this.pointsVel.splice(i, 0, vel);
      }
    }
    movePoints() {
      const pointsVel = this.pointsVel.slice();
      for (let i = 0; i < this.points.length; ++i) {
        const prevVel =
          pointsVel[(i - 1 + this.points.length) % this.points.length];
        const nextVel = pointsVel[(i + 1) % this.points.length];
        const newVel = Math.max(
          Math.min((this.pointsVel[i] + Math.random() - 0.5) * 0.7, 10),
          -10
        );
        this.pointsVel[i] = (prevVel + nextVel + 8 * newVel) / 10;
      }
      for (let i = 0; i < this.points.length; ++i) {
        const curP = this.points[i];
        const prevRl =
          this.points[(i - 1 + this.points.length) % this.points.length].rl;
        const nextRl = this.points[(i + 1) % this.points.length].rl; // here
        let curRl = curP.rl;
        let affected = quadtree.some(
          {
            x: curP.x - 5,
            y: curP.y - 5,
            w: 10,
            h: 10,
          },
          (item) => item.parent !== this && sqDist(item, curP) <= 25
        );
        if (
          !affected &&
          (curP.x < border.left ||
            curP.y < border.top ||
            curP.x > border.right ||
            curP.y > border.bottom)
        ) {
          affected = true;
        }
        if (affected) {
          this.pointsVel[i] = Math.min(this.pointsVel[i], 0) - 1;
        }
        curRl += this.pointsVel[i];
        curRl = Math.max(curRl, 0);
        curRl = (9 * curRl + this.s) / 10;
        curP.rl = (prevRl + nextRl + 8 * curRl) / 10;

        const angle = (2 * Math.PI * i) / this.points.length;
        let rl = curP.rl;
        if (this.isVirus && i % 2 === 0) {
          rl += 5;
        }
        curP.x = this.x + Math.cos(angle) * rl;
        curP.y = this.y + Math.sin(angle) * rl;
      }
    }
    setName(rawName) {
      const { name, skin } = Cell.parseName(rawName);
      this.name = name;
      this.setSkin(skin);
    }
    setSkin(value) {
      this.skin =
        (value && value[0] === "%" ? value.slice(1) : value) || this.skin;
      if (
        this.skin === null ||
        !knownSkins.has(this.skin) ||
        loadedSkins.has(this.skin)
      ) {
        return;
      }
      const skin = new Image();
      skin.src = `${SKIN_URL}${this.skin}.png`;
      loadedSkins.set(this.skin, skin);
    }
    setColor(value) {
      if (!value) {
        Logger.warn("Got no color");
        return;
      }
      this.color = value;
      this.sColor = value.darker();
    }
    draw(ctx) {
      //ctx.save();
      if (this.x + this.s < camera.x - mainCanvas.width / 2 / camera.scale) return;
      if (this.x - this.s > camera.x+ mainCanvas.width / 2 / camera.scale) return;
      if (this.y + this.s < camera.y - mainCanvas.height / 2 / camera.scale) return;
      if (this.y - this.s > camera.y + mainCanvas.height / 2 / camera.scale) return;
      this.drawShape(ctx);
      this.drawText(ctx);
      //ctx.restore();
    }
    drawShape(ctx) {

      if (this.s <= 0)
        return;
    
      ctx.fillStyle = settings.showColor ? this.color.toHex() : "#FFFFFF";

      let stroke = settings.strokeCell && !this.isPellet;

      if (stroke) {
        ctx.strokeStyle = settings.showColor ? this.sColor.toHex() : "#E5E5E5";  
        ctx.lineWidth = Math.min(Math.max(~~(this.s / 50), 10), this.s / 2);
        this.s -= ctx.lineWidth / 2;
      }

      if (this.destroyed) {
        ctx.globalAlpha = Math.max(120 - Date.now() + this.dead, 0) / 120;
      } else {
        ctx.globalAlpha = Math.min(Date.now() - this.born, 120) / 120;
      }

      if (this.isVirus) {
        ctx.lineJoin = "miter";
      }

      ctx.beginPath();
      if (settings.jellyPhysics) {
        //Jelly disabled based on size is greyed out.
        const point = this.points[0];
        ctx.moveTo(point.x, point.y);
        for (const point of this.points) ctx.lineTo(point.x, point.y);
        ctx.closePath();
      } else {
        if (this.isVirus) {
          const pointCount = 50;
          const incremental = PI_2 / pointCount;
          ctx.moveTo(this.x, this.y + this.s + 3);
          for (let i = 1; i < pointCount; i++) {
            const angle = i * incremental;
            const dist = this.s - 3 + (i % 2 === 0) * 6;
            ctx.lineTo(
            this.x + dist * Math.sin(angle),
            this.y + dist * Math.cos(angle));
          }
          ctx.lineTo(this.x, this.y + this.s + 3);
          ctx.closePath();
        } else {
          ctx.arc(this.x, this.y, this.s, 0, PI_2, false);
        }
      }

      const skinImage = loadedSkins.get(this.skin);
      if (
        settings.showSkins &&
        this.skin &&
        skinImage &&
        skinImage.complete &&
        skinImage.width &&
        skinImage.height
      ) {
        if (settings.fillSkin) ctx.fill();
        ctx.save();
        ctx.clip();
        ctx.drawImage(
          skinImage,
          this.x - this.s,
          this.y - this.s,
          this.s * 2,
          this.s * 2
        );
        ctx.restore();
      } else {
        ctx.fill();
      }

      if (stroke) {
        ctx.stroke();
        this.s += ctx.lineWidth / 2;
      }
    }
    drawText(ctx) {
      if (this.s < 20 || this.isVirus || this.isEjectedMass || this.isPellet) return;

      const scaledNameSize = this.nameSize;
      const scaledDrawNameSize = this.drawNameSize;

      if (this.name && settings.showNames) {
        drawText(
          ctx,
          false,
          this.x,
          this.y,
          scaledNameSize,
          scaledDrawNameSize,
          this.name
        );
      }

      if (
        settings.showMass &&
        (cells.mine.indexOf(this.id) !== -1 || cells.mine.length === 0)
      ) {
        const mass = (~~((this.s * this.s) / 100)).toString();
        let y = this.y;
        if (this.name && settings.showNames)
          y += Math.max(this.s / 4.5, scaledNameSize / 1.5); // Adjusted for scaleFactor
        drawText(
          ctx,
          true,
          this.x,
          y,
          scaledNameSize / 2,
          scaledDrawNameSize / 2,
          mass
        );
      }
    }
  }

  function cacheCleanup() {
    for (const i of cachedNames.keys()) {
      for (const j of cachedNames.get(i).keys()) {
        if (syncAppStamp - cachedNames.get(i).get(j).accessTime >= 5000) {
          cachedNames.get(i).delete(j);
        }
      }
    }
    for (const i of cachedMass.keys()) {
      if (syncAppStamp - cachedMass.get(i).accessTime >= 5000) {
        cachedMass.delete(i);
      }
    }
  }

  // 2-var draw-stay cache
  const cachedNames = new Map();
  const cachedMass = new Map();
  window.cachedNames = cachedNames;
  window.cachedMass = cachedMass;

  function drawTextOnto(canvas, ctx, text, size) {
    ctx.font = size + "px Ubuntu";
    ctx.lineWidth = Math.max(~~(size / 10), 2);
    canvas.width = ctx.measureText(text).width + 2 * ctx.lineWidth;
    canvas.height = 4 * size;
    ctx.font = size + "px Ubuntu";
    ctx.lineWidth = Math.max(~~(size / 10), 2);
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillStyle = "#FFF";
    ctx.strokeStyle = "#000";
    ctx.translate(canvas.width / 2, 2 * size);
    ctx.lineWidth !== 1 && ctx.strokeText(text, 0, 0);
    ctx.fillText(text, 0, 0);
  }
  function drawRaw(ctx, x, y, text, size) {
    ctx.font = size + "px Ubuntu";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.lineWidth = Math.max(~~(size / 10), 2);
    ctx.fillStyle = "#FFF";
    ctx.strokeStyle = "#000";
    ctx.lineWidth !== 1 && ctx.strokeText(text, x, y);
    ctx.fillText(text, x, y);
    ctx.restore();
  }
  function newNameCache(value, size) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    drawTextOnto(canvas, ctx, value, size);
    if (!cachedNames.has(value)) cachedNames.set(value, new Map());
    const cache = {
      width: canvas.width,
      height: canvas.height,
      canvas: canvas,
      value: value,
      size: size,
      accessTime: syncAppStamp,
    };
    cachedNames.get(value).set(size, cache);
    return cache;
  }
  function newMassCache(size) {
    const canvases = {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
    };
    for (const i in canvases) {
      const canvas = (canvases[i].canvas = document.createElement("canvas"));
      const ctx = canvas.getContext("2d");
      drawTextOnto(canvas, ctx, i, size);
      canvases[i].canvas = canvas;
      canvases[i].width = canvas.width;
      canvases[i].height = canvas.height;
    }
    const cache = {
      canvases: canvases,
      size: size,
      lineWidth: Math.max(~~(size / 10), 2),
      accessTime: syncAppStamp,
    };
    cachedMass.set(size, cache);
    return cache;
  }
  function toleranceTest(a, b, tolerance) {
    return a - tolerance <= b && b <= a + tolerance;
  }
  function getNameCache(value, size) {
    if (!cachedNames.has(value)) return newNameCache(value, size);
    const sizes = Array.from(cachedNames.get(value).keys());
    for (let i = 0, l = sizes.length; i < l; i++) {
      if (toleranceTest(size, sizes[i], size / 4)) {
        return cachedNames.get(value).get(sizes[i]);
      }
    }
    return newNameCache(value, size);
  }
  function getMassCache(size) {
    const sizes = Array.from(cachedMass.keys());
    for (let i = 0, l = sizes.length; i < l; i++) {
      if (toleranceTest(size, sizes[i], size / 4)) {
        return cachedMass.get(sizes[i]);
      }
    }
    return newMassCache(size);
  }

  function drawText(ctx, isMass, x, y, size, drawSize, value) {
    ctx.save();
    if (size > 500) return drawRaw(ctx, x, y, value, drawSize);
    ctx.imageSmoothingQuality = settings.jellyPhysics ?  "high" : "low";
    if (isMass) {
      const cache = getMassCache(size);
      cache.accessTime = syncAppStamp;
      const canvases = cache.canvases;
      const correctionScale = drawSize / cache.size;

      // calculate width
      let width = 0;
      for (let i = 0; i < value.length; i++) {
        width += canvases[value[i]].width - 2 * cache.lineWidth;
      }

      ctx.scale(correctionScale, correctionScale);
      x /= correctionScale;
      y /= correctionScale;
      x -= width / 2;
      for (let i = 0; i < value.length; i++) {
        const item = canvases[value[i]];
        ctx.drawImage(item.canvas, x, y - item.height / 2);
        x += item.width - 2 * cache.lineWidth;
      }
    } else {
      const cache = getNameCache(value, size);
      cache.accessTime = syncAppStamp;
      const canvas = cache.canvas;
      const correctionScale = drawSize / cache.size;
      ctx.scale(correctionScale, correctionScale);
      x /= correctionScale;
      y /= correctionScale;
      ctx.drawImage(canvas, x - canvas.width / 2, y - canvas.height / 2);
    }
    ctx.restore();
  }
  function processKey(event) {
    let key = CODE_TO_KEY[event.code] || event.code.toLowerCase();
    if (Object.hasOwnProperty.call(IE_KEYS, key)) key = IE_KEYS[key]; // IE fix
    return key;
  }
  window.addEventListener("mousedown", function (event) {

    if (event.button === 0) {
      const buf = new ArrayBuffer(1);
      const view = new DataView(buf);

      view.setUint8(0, 46);

      const leftMouseClickMessage = new Uint8Array(buf);
      wsSend(leftMouseClickMessage);
    }
  });
  function keydown(event) {
    const key = processKey(event);
    if (pressed[key]) return;
    if (Object.hasOwnProperty.call(pressed, key)) pressed[key] = true;

    if (keyDownActions[key]) {
      keyDownActions[key]();
    }
  }

  function keyup(event) {
    const key = processKey(event);
    if (Object.hasOwnProperty.call(pressed, key)) {
      pressed[key] = false;
    }

    if (keyUpActions[key]) {
      keyUpActions[key]();
    }
  }

  function handleScroll(event) {
    if (event.target !== mainCanvas) return;
    camera.userZoom *= event.deltaY > 0 ? 0.8 : 1.2;
    camera.userZoom = Math.max(camera.userZoom, settings.moreZoom ? 0.6 : 0.8);
    camera.userZoom = Math.min(camera.userZoom, 4);
  }

  function init() {
    mainCanvas = document.getElementById("canvas");
    mainCtx = mainCanvas.getContext("2d");
    chatBox = byId("chat_textbox");
    soundsVolume = byId("soundsVolume");
    mainCanvas.focus();

    loadSettings();
    window.addEventListener("beforeunload", storeSettings);
    document.addEventListener("wheel", handleScroll, { passive: true });
    byId("play-btn").addEventListener("click", async () => {
      const skin = settings.skin;
      const name = settings.nick;
      const nicknameInput = byId("nick"); // Adjust this ID based on your actual input element's ID
  
      if (!name.trim()) {
          // Make the nickname input flash red
          nicknameInput.classList.add("flash-red");
  
          // Optionally, remove the class after the animation ends to reset the state
          nicknameInput.addEventListener('animationend', () => {
              nicknameInput.classList.remove("flash-red");
          }, {once: true});
  
          // Stop the function here if the nickname is empty
          return;
      }
  
      sendPlay(name, skin);
      hideESCOverlay();
      players = false;
    });
    window.onkeydown = keydown;
    window.onkeyup = keyup;
    chatBox.onblur = () => {
      isTyping = false;
      drawChat();
    };
    chatBox.onfocus = () => {
      isTyping = true;
      drawChat();
    };
    mainCanvas.onmousemove = (event) => {
      mouseX = event.clientX;
      mouseY = event.clientY;
    };
    setInterval(() => {
      sendMouseMove(
        (mouseX - mainCanvas.width / 2) / camera.scale + camera.x,
        (mouseY - mainCanvas.height / 2) / camera.scale + camera.y
      );
    }, 40);
    window.onresize = () => {
      const width = (mainCanvas.width = window.innerWidth);
      const height = (mainCanvas.height = window.innerHeight);
      camera.viewportScale = Math.max(width / 1920, height / 1080);
    };
    window.onresize();
    const mobileStuff = byId("mobileStuff");
    // eslint-disable-next-line
    const touchpad = byId("touchpad");
    const touchCircle = byId("touchCircle");
    const touchSize = 0.2;
    let touched = false;
    const touchmove = (event) => {
      const touch = event.touches[0];
      const width = innerWidth * touchSize;
      const height = innerHeight * touchSize;
      if (touch.pageX < width && touch.pageY > innerHeight - height) {
        mouseX =
          innerWidth / 2 + ((touch.pageX - width / 2) * innerWidth) / width;
        mouseY =
          innerHeight / 2 +
          ((touch.pageY - (innerHeight - height / 2)) * innerHeight) / height;
      } else {
        mouseX = touch.pageX;
        mouseY = touch.pageY;
      }
      const r = innerWidth * 0.02;
      touchCircle.style.left = mouseX - r + "px";
      touchCircle.style.top = mouseY - r + "px";
    };
    window.addEventListener("touchmove", touchmove);
    window.addEventListener("touchstart", (event) => {
      if (!touched) {
        touched = true;
        mobileStuff.show();
      }
      if (event.target.id === "splitBtn") {
        wsSend(UINT8_CACHE[17]);
      } else if (event.target.id === "ejectBtn") {
        wsSend(UINT8_CACHE[21]);
      } else {
        touchmove(event);
      }
      touchCircle.show();
    });
    window.addEventListener("touchend", (event) => {
      if (event.touches.length === 0) {
        touchCircle.hide();
      }
    });

    gameReset();
    showESCOverlay();

    drawGame();
    Logger.info(`Init done in ${Date.now() - LOAD_START}ms`);
  }
  window.setserver = (url) => {
    if (url === wsUrl && ws && ws.readyState <= WebSocket.OPEN) return;
    wsInit(url);
    gameReset();
    players = false;
  };
  window.spectate = (/* a */) => {
    spectateDefault = true;
    const writer = new Writer(true);
    writer.setUint8(UINT8_CACHE[1]); // Packet ID for play
    const name = settings.nick;
    writer.setStringUTF8(name); // Send name
    wsSend(writer);

    stats.maxScore = 0;
    hideESCOverlay();

    if (
      stats.info &&
      (stats.info.playersAlive > 0 || stats.info.playerBots > 0)
    ) {
      players = true;
    } else {
      players = false;
    }
  };
  
  window.changeSkin = (a) => {
    byId("skin").value = a;
    settings.skin = a;

    const selectedSkinImg = byId("selectedSkinImg");
    const selectedSkinName = byId("selectedSkinName");

    selectedSkinImg.src = `./assets/static/skins/${a}.png`;
    selectedSkinImg.classList.add("selected-skin-animation");
    selectedSkinName.textContent = a;
  };
  window.removeSelectedSkin = () => {
    byId("selectedSkinImg").src = "./assets/static/img/defaultSkin.png";
    byId("selectedSkinName").textContent = "Default Skin";
    byId("skin").value = ""; // Resets the skin input value
    settings.skin = ""; // Resets the skin setting
  };
  window.openSkinsList = () => {
    if (byId("gallery-body").innerHTML === "") buildGallery();
    byId("gallery").show(0.5);
  };
  window.addEventListener("load", function () {
    const ffaButton = document.getElementById("ffa-btn");
    const teamsButton = document.getElementById("teams-btn");
    const duelsButton = document.getElementById("duels-btn");
    const experimentalButton = document.getElementById("experimental-btn");
    window.setserver(startServer);
    selectedServer(startServer);
    ffaButton.addEventListener("click", function () {
      document.getElementById("experimental-btn").style.backgroundColor = "";
      document.getElementById("teams-btn").style.backgroundColor = "";
      document.getElementById("duels-btn").style.backgroundColor = "";
      document.getElementById("ffa-btn").style.backgroundColor ="rgb(56 74 119)";
      maxAttempts = 0;
      currentServer = (ffa);

      retryConnection(currentServer);
      currentServerString = "Free for All"
    });
    teamsButton.addEventListener("click", function () {
      document.getElementById("ffa-btn").style.backgroundColor = "";
      document.getElementById("duels-btn").style.backgroundColor = "";
      document.getElementById("experimental-btn").style.backgroundColor = "";
      document.getElementById("teams-btn").style.backgroundColor =
        "rgb(146 100 148)";
      maxAttempts = 0;
      currentServer = (teams);
      retryConnection(currentServer);
      currentServerString = "Team Mode"
    });
    duelsButton.addEventListener("click", function () {
      document.getElementById("teams-btn").style.backgroundColor = "";
      document.getElementById("ffa-btn").style.backgroundColor = "";
      document.getElementById("duels-btn").style.backgroundColor ="rgb(160 60 60)";
      document.getElementById("experimental-btn").style.backgroundColor = "";
      maxAttempts = 0;
      currentServer = (duels);
      retryConnection(currentServer);
      currentServerString = "1vs1"
    });
    experimentalButton.addEventListener("click", function () {
      document.getElementById("teams-btn").style.backgroundColor = "";
      document.getElementById("duels-btn").style.backgroundColor = "";
      document.getElementById("ffa-btn").style.backgroundColor = "";
      document.getElementById("experimental-btn").style.backgroundColor ="rgb(98 154 80)";
      maxAttempts = 0;
      currentServer = (experimental);
      retryConnection(currentServer);
      currentServerString = "Mega-Split"
    });
  });
  function selectedServer(startServer) {
    if (startServer === ffa) {
      document.getElementById("ffa-btn").style.backgroundColor =
        "rgb(56 74 119)";
    } else if (startServer === teams) {
      document.getElementById("teams-btn").style.backgroundColor =
        "rgb(146 100 148)";
    } else if (startServer === duels) {
      document.getElementById("duels-btn").style.backgroundColor =
        "rgb(160 60 60)";
    } else if (startServer === experimental) {
      document.getElementById("experimental-btn").style.backgroundColor =
        "rgb(98 154 80)";
    }
  }
  $(document).ready(function () {
    $("#settings-btn").click(function () {
      $("#settings").toggle();
    });
  });
  window.addEventListener("DOMContentLoaded", init);
})();
